import React from "react";
import echarts from "echarts";
import "echarts/map/js/world";
import "./App.css";
import { getLatLngObj } from "tle.js";
import { getSatelliteName } from "tle.js";
import _ from 'lodash';

class App extends React.Component {
  options = {
    backgroundColor: new echarts.graphic.RadialGradient(0.5, 0.5, 0.4, [
      {
        offset: 0,
        color: "#4b5769",
      },
      {
        offset: 1,
        color: "#404a59",
      },
    ]),
    title: {
      text: "Satllite Tracker",
      subtext: "Satellite path predition using telemetry from NASA/NORAD",
      left: "center",
      top: 5,
      itemGap: 0,
      textStyle: {
        color: "#eee",
      },
      z: 200,
    },
    /*tooltip: {
      trigger: "item",
      formatter: function (params) {
        var value = params.value[0] + " , " + params.value[1];
        return (
          params.name +
          " : " +
          value +
          "<br/>" +
          "Last updated at : " +
          new Date(params.value[2] * 1000)
        );
      },
    },*/
    brush: {
      geoIndex: 0,
      brushLink: "all",
      inBrush: {
        opacity: 1,
        symbolSize: 14,
      },
      outOfBrush: {
        color: "#000",
        opacity: 0.2,
      },
      z: 10,
    },
    geo: {
      map: "world",
      silent: false,
      emphasis: {
        label: {
          show: true,
          areaColor: "#eee",
        },
      },
      itemStyle: {
        borderWidth: 0.2,
        borderColor: "#404a59",
      },
      roam: true,
    },
    series: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      result: { iss_position: { longitude: 0, latitude: 0 } },
      chartInstance: undefined,
      myLocation: {},
      issLocation: {},
      satList: {},
      visual: {},
      plotSeries : {},
      plotSeries1 : {},
      plotSeries2 : {},
      plotSeries3 : {},
      plotSeries4 : {},
      plotSeries5 : {},
    };
  }

  reorganizehartData() {
    this.options.series = [
      {
        type: "scatter",
        coordinateSystem: "geo",
        symbolSize: 8,
        data: [
        ..._.map(this.state.plotSeries, item => ({
          name: item.name,
          value: [item.longitude, item.latitude, item.timestamp],
          symbolSize: 5,
            itemStyle: {
              borderColor: "#D5F5E32",
              color: "#F39C12",
            },
            emphasis: {
              label: {
                show: true,
              },
            },
        })),
        ..._.map(this.state.plotSeries1, item => ({
          //name: item.name,
          value: [item.longitude, item.latitude, item.timestamp],
        })),
        ..._.map(this.state.plotSeries2, item => ({
          //name: item.name,
          value: [item.longitude, item.latitude, item.timestamp],
        })),
        ..._.map(this.state.plotSeries3, item => ({
          name: item.name,
          value: [item.longitude, item.latitude, item.timestamp],
        })),
        ..._.map(this.state.plotSeries4, item => ({
          name: item.name,
          value: [item.longitude, item.latitude, item.timestamp],
        })),
        ..._.map(this.state.plotSeries5, item => ({
          name: item.name,
          value: [item.longitude, item.latitude, item.timestamp],
        })),
          {
            name: "ISS",
            value: [
              this.state.result.iss_position.longitude,
              this.state.result.iss_position.latitude,
              this.state.result.timestamp,
            ],
            symbolSize: 15,
            itemStyle: {
              borderColor: "#FF5733",
              color: "#FF5733",
            },
            emphasis: {
              label: {
                show: true,
              },
            },
          },
          /*{
            name: "ISS ZARYA",
            value: [
              this.state.issLocation.longitude,
              this.state.issLocation.latitude,
              this.state.issLocation.timestamp,
            ],
          },*/
          {
            name: "My Location",
            value: [
              this.state.myLocation.longitude,
              this.state.myLocation.latitude,
              this.state.myLocation.timestamp,
            ],
            activeOpacity: .75,
            symbolSize: 30,
            itemStyle: {
              borderColor: "#F39C12",
              color: "#D5F5E3",
            },
            emphasis: {
              label: {
                show: true,
              },
            },
          },
        ],
        activeOpacity: .5,
        label: {
          formatter: "{b}",
          position: "right",
          show: false,
        },
        symbolSize: 1,
        /*itemStyle: {
            normal: {
                areaColor: '#323c48',
                borderColor: '#111'
            },
            emphasis: {
                areaColor: '#2a333d'
            }
        },*/
        itemStyle: {
          borderColor: "#577ceb",
          color: "#577ceb",
        },
        emphasis: {
          label: {
            show: false,
          },
        },
      },
    ];
    this.state.chartInstance.setOption(this.options);
  }

  fetchData() {
    fetch("https://sheltered-spire-38600.herokuapp.com/iss-now.json")
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState(
            Object.assign({}, this.state, {
              isLoaded: true,
              result,
            })
          );
          //this.reorganizehartData();
        },
        (error) => {
          this.setState(
            Object.assign({}, this.state, {
              isLoaded: true,
              error,
            })
          );
        }
      );
  }

  readTextFile = file => {
        var rawFile = new XMLHttpRequest();
        rawFile.open("GET", file, false);
        //console.log(rawFile);
        rawFile.onreadystatechange = () => {
          //console.log(rawFile);
            if (rawFile.readyState === 4) {
                if (rawFile.status === 200 || rawFile.status == 0) {
                    var allText = rawFile.responseText;
                    console.log("allText: ", allText);
                    this.setState({
                        fundData: allText
                    });
                }
            }
        };
        //rawFile.send(null);
    };

  fetchVisual() {

    fetch(window.location.origin + "/NORAD/elements/visual.txt")
      .then(response => response.text())
      .then((visual) => {
        //console.log(visual)

        this.setState(
            Object.assign({}, this.state, {
              isLoaded: true,
              visual,
            })
          );

    })

  }


  fetchSplitVisual() {

    //console.log(this.state.visual);
    //console.log(`Found dat: ${this.state.visual}`);

  const parsed =  _.chain(this.state.visual).split('\n').reduce((acc, item) => {
    let index = acc.index;
const rawIndex = Math.floor(index/3);
let val = acc.arr[rawIndex];
const indexAdjusted = (index+1)%3;
val += (indexAdjusted!= 1?'\n':'')+item;
acc.arr[rawIndex] = val;

  index++;
if(indexAdjusted == 0) {
  acc.arr[rawIndex+1] = '';
}

  acc.index = index;
return acc;
    }, {arr: [''], index: 0}).get('arr', []).filter(item => item.length != 0).value();

//console.log(parsed);

this.state.satList = parsed;

//console.log(this.state.satList);
    //let m;

    //console.log(str);
    //const regex = /(?=[\s\S])(?:.*\n?){1,3}/g;

/*
    while ((m = regex.exec(this.state.visual)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }
    
      // The result can be accessed through the `m`-variable.
      m.forEach((match, groupIndex) => {
        console.log(`Found match: ${match}`);
    });
   }
   */

   //console.log(m);
          //this.reorganizehartData();

  }

  getMyLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.setState(
          Object.assign({}, this.state, {
            myLocation: {
              longitude: position.coords.longitude,
              latitude: position.coords.latitude,
              timestamp: position.timestamp,
            },
          })
        );
      });
    }
  }

  getIssLocation() {
    // Satellite TLE; should be updated at least once a day for best results.
    // TLE source: http://celestrak.com/NORAD/elements/
    const tle = `ISS (ZARYA)
    1 25544U 98067A   20143.56318382  .00000454  00000-0  16202-4 0  9994
    2 25544  51.6434 115.2941 0001418 343.5417 126.5978 15.49381561228046`;
    
    //const optionalTimestampMS = 1502342329860;
    const timestamp = new Date();
    const latLonObj = getLatLngObj(tle, timestamp);

    // if (latLonObj) {
      this.setState(
        Object.assign({}, this.state, {
          issLocation: {
            longitude: latLonObj.lng,
            latitude: latLonObj.lat,
            timestamp,
          },
        })
      );
      this.reorganizehartData();
    // }
  }

  getSatLocations() {
    const timestamp = new Date();
    const plotSeries = _.chain(this.state.satList).map(item  => ({tle: item, value: getLatLngObj(item, timestamp)})).map(item => ({
            name:getSatelliteName(item.tle),
            longitude: item.value.lng,
            latitude: item.value.lat,
            timestamp,
          })).value()
    this.getSatLatLong(plotSeries);

    this.reorganizehartData();
  }

  getSatLatLong(plotSeries) {
      this.setState(
        Object.assign({}, this.state, {
          plotSeries,
        })
      );
}

  getSatPath() {
    const plotSeries5 = this.state.plotSeries4;
      this.setState(
        Object.assign({}, this.state, {
          plotSeries5,
        })
      );
    const plotSeries4 = this.state.plotSeries3;
      this.setState(
        Object.assign({}, this.state, {
          plotSeries4,
        })
      );

    const plotSeries3 = this.state.plotSeries2;
      this.setState(
        Object.assign({}, this.state, {
          plotSeries3,
        })
      );
    const plotSeries2 = this.state.plotSeries1;
      this.setState(
        Object.assign({}, this.state, {
          plotSeries2,
        })
      );
    const plotSeries1 = this.state.plotSeries;
      this.setState(
        Object.assign({}, this.state, {
          plotSeries1,
        })
      );
}

  componentDidMount() {
    this.setState(
      Object.assign({}, this.state, {
        chartInstance: echarts.init(document.getElementById("main")),
      })
    );
    setTimeout(this.loadData.bind(this), 1000);
  }

  loadData() {
    this.fetchData();
    setInterval(this.fetchData.bind(this), 5000);
    this.getIssLocation();
    setInterval(this.getIssLocation.bind(this), 1000);
    this.getMyLocation();
    this.fetchVisual();
    setTimeout(this.fetchSplitVisual.bind(this), 1000);
    setInterval(this.getSatLocations.bind(this), 1000);
    setInterval(this.getSatPath.bind(this), 10000);
  }

  render() {
    return (
      <div>
        <div style={{ width: "100%", height: "100%" }}>
          <div id="main" style={{ height: "100vh" }}></div>
        </div>
      </div>
    );
  }
}

export default App;
